<template>
  <div v-if="data" class="attached-members-card card">
    <router-link :to="`/user/${data.id}`" v-if="sendRequest" class="card__backdrop" tag="div"/>
    <img
        v-if="sendRequest"
        @click="showAlert = true"
        class="card__trash"
        src="@/assets/svg/icon-trash.svg"
        alt=""
    >
    <div class="card__top">
      <div class="card__top-data">
        <ui-checkbox v-if="active" v-model="data.checked" />
        <router-link :to="`/user/${data.id}`" class="card__top-data-avatar" tag="img" :src="getUserAvatar(data.avatar, true)" />
        <router-link :to="`/user/${data.id}`" class="card__top-data-info">
          <p class="name">{{ getTitle }}</p>
          <div class="role">
            <img :src="getUserRole(data.role).icon" alt="" />
            <p>{{ $t(getUserRole(data.role).text) }}</p>
            <span
                v-if="
                (getUser.role !== 'Company' && getUser.role !== 'Government')&&
                data.role === 'Government'&&
                getUserName(data.firstName, data.lastName).length > 1"
            >
              {{ getUserName(data.lastName, data.firstName, data?.middleName) }}</span>
            <span>{{ $t('requests-length', {from: data.acceptedOrdersCount, to: data.totalOrdersCount}) }}</span>
          </div>
        </router-link>
      </div>
      <img
          v-if="!active && getUser.id === getOrderItem.moderatedBy && getOrderItem.status === 'Accepted' && !sendRequest"
          @click="showAlert = true"
          class="card__top-data-icon-trash"
          src="@/assets/svg/icon-trash.svg"
          alt=""
      >
      <img
          src="@/assets/svg/arrows/up-black.svg"
          alt=""
          @click="visibleRequestWindow = !visibleRequestWindow"
          :class="['arrow', { rotate: visibleRequestWindow }]"
          v-if="isRequest"
      />
    </div>
    <div v-if="visibleRequestWindow" class="card__bottom">
      <div class="card__bottom-description">
        <p>{{ $t('purpose-of-attachment') }}</p>
        <span>{{ data.orderParticipation.requestComment }}</span>
      </div>
      <div class="card__bottom-documents">
        <p class="card__bottom-documents-title">{{ $t('documents') }}</p>
        <div class="card__bottom-documents-wrap">
          <a
              v-for="(item, key) in data.orderParticipation.requestFilesSrc"
              :key="key"
              :href="item.fileSrc"
              target="_blank"
              style="display: flex; align-items: center; gap: 10px;"
              class="card__bottom-documents-wrap-item"
          >
            <img
                src="@/assets/svg/icon-docs-grey.svg"
                alt=""
            />
            <div class="card__bottom-documents-wrap-item-info">
              <p>{{ item.name || item.originalname }}</p>
              <span>{{ getFullDate(item.createdAt || new Date())}}</span>
            </div>
          </a>
        </div>
      </div>
      <div class="card__bottom-form">
        <ui-textarea v-model="description" height="65" radius="10" :label="$t('reason-for-rejection')" :required-field="true" />
        <ui-button :disabled="!description" @click="rejectOrderRequest">{{ $t('reject') }}</ui-button>
      </div>
    </div>
    <ui-alert v-model="showAlert">
      <p class="ui-alert__title">{{ $t('delete-member') }}</p>
      <div class="ui-alert__action">
        <ui-button @click="deleteOrder" color="error">{{ $t('delete') }}</ui-button>
        <ui-button @click="showAlert = false" color="white">{{ $t('cancel-1') }}</ui-button>
      </div>
    </ui-alert>
  </div>
</template>

<script>
import { getUserAvatar, getUserRole } from "@/utils/user";
import {mapActions, mapGetters} from "vuex";
import {getFullDate} from "../../../../utils/general";
import UiTextarea from "@/components/ui/UiTextarea.vue";

export default {
  name: "AttachedMembersCard",
  components: {
    UiTextarea,
    UiCheckbox: () => import('@/components/ui/UiCheckbox'),
    UiAlert: () => import('@/components/ui/UiAlert'),
    UiButton: () => import('@/components/ui/UiButton'),
  },

  props: {
    active: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {}
    },
    isRequest: {
      type: Boolean,
      default: false
    },
    sendRequest: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      getUserAvatar,
      getUserRole,
      checkbox: false,
      showAlert: false,
      visibleRequestWindow: false,
      description: ''
    }
  },

  computed: {
    ...mapGetters(['getUser', 'getOrderItem']),
    getTitle() {
      return this.data.role === 'Inspector' || this.data.role === 'Lawyer' ? this.getUserName(this.data.lastName, this.data.firstName, this.data.middleName) : '' ||
      this.data.role === 'Company' ? this.data.companyName : '' ||
      this.data.role === 'Government' ? this.data.companyName : ''
    },
  },

  methods: {
    ...mapActions(['sendParticipantsDeny']),
    getFullDate,
    getUserName(name, surname, middlename) {
      if(name && surname && middlename) {
        return name + ' ' + surname.split('')[0].toUpperCase() + '.' + middlename.split('')[0].toUpperCase() + '.'
      }else {
        return name + ' ' + surname.split('')[0].toUpperCase() + '.'
      }
    },

    deleteOrder() {
      this.$emit('removeCard', this.data)
      this.showAlert = false
    },

    rejectOrderRequest () {
      this.$emit('denyParticipant', {id: this.data.orderParticipation.orderId, denyComment: this.description, ids: [this.data.id]})
    }
  },
}
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;

  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(200, 200, 200, 0.3);
    z-index: 3;
    border-radius: 200px;
  }

  &__trash {
    position: absolute;
    top: 22px;
    right: 20px;
    width: 16px;
    height: 20px;
    cursor: pointer;
    z-index: 3;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    border: 1px solid #F5F5F5;
    box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
    border-radius: 200px;
    padding: 5px 20px;
    z-index: 2;

    &-data {
      display: flex;
      align-items: center;
      gap: 10px;

      &-avatar {
        width: 50px;
        height: 50px;
        border: 1px solid #CE2121;
        border-radius: 50%;
        object-fit: cover;
        cursor: pointer;
      }

      &-info {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .name {
          font-weight: 400;
          font-size: 16px;
          line-height: 15px;
          color: #343432;
        }

        .role {
          display: flex;
          align-items: center;
          gap: 5px;

          img {
            width: 10px;
            height: 10px;
          }

          p {
            font-weight: 400;
            font-size: 12px;
            line-height: 11px;
            color: #9A9A9A;
          }

          span {
            display: flex;
            align-items: center;
            gap: 5px;
            font-weight: 400;
            font-size: 12px;
            line-height: 11px;
            color: #9A9A9A;

            &::before {
              content: '';
              display: block;
              width: 2px;
              height: 2px;
              background: #9A9A9A;
              border-radius: 50%;
            }
          }
        }
      }
    }

    &-icon-trash {
      width: 16px;
      height: 20px;
      cursor: pointer;
    }

    .arrow {
      cursor: pointer;
      transform: rotate(180deg);
      transition: 0.5s;

      @media (max-width: 992px) {
        top: 45px;
        right: 19px;
      }

      &.rotate {
        transform: rotate(0deg) !important;
      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
    border: 2px dashed #D9D9D9;
    z-index: 1;
    margin: -40px 20px 0 20px;

    &-description {
      display: flex;
      flex-direction: column;
      gap: 10px;

      p {
        color: #9A9A9A;
        font-size: 14px;
        font-weight: 400;
      }

      span {
        color: #1B1A1F;
        font-size: 14px;
        font-weight: 400;
      }
    }

    &-documents {
      display: flex;
      align-items: center;
      gap: 5px;
      position: relative;

      &-title {
        color: #9A9A9A;
        font-size: 14px;
        font-weight: 400;
        position: absolute;
        top: 0;
        left: 0;
      }

      &-wrap {
        display: flex;
        flex-wrap: nowrap;
        gap: 20px;
        overflow-x: auto;
        right: 1rem;
        width: 100%;
        max-width: 500px;
        height: 100%;
        padding: 10px 0;

        &::-webkit-scrollbar {
          width: 20px;
          height: 4px;
        }

        &::-webkit-scrollbar-thumb {
          background: #9A9A9A;
          border-radius: 10px;
        }

        &-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 30px;
          min-width: 114px;
          max-width: 116px;

          &-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;

            @media (max-width: 768px) {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 4px;
            }

            p {
              font-weight: 400;
              font-size: 16px;
              line-height: 15px;
              color: #1B1A1F;
              word-break: break-all;

              @media (max-width: 768px) {
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                text-align: center;
                color: #1B1A1F;
              }
            }

            span {
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
              color: #BFBDBD;

              @media (max-width: 768px) {
                font-weight: 400;
                font-size: 8px;
                line-height: 112.7%;
                color: #BFBDBD;
                width: 42px;
                text-align: center;
              }
            }
          }
        }
      }
    }

    &-form {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  ::v-deep .ui-alert__action {
    display: flex !important;
    flex-direction: row !important;
    gap: 8px !important;
  }
}
::v-deep .ui-alert__title {
  max-width: 100% !important;
  width: 100% !important;
}
</style>
